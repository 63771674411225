.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 29px;
}

.aboutMeSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.aboutMeSectionHeadingRow {
 width: 100%;
 height: auto;
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.aboutMeSectionHeading {
  color: #37484b;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.aboutMeSectionHeadingRowButtons{
  width: 370px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aboutMeSectionContent {
  color: rgba(51, 51, 51, 0.6);
  font-size: 11pt;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}

.trainingAndCredentialsSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.trainingAndCredentialsSectionHeading {
  color: #37484b;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.trainingAndCredentialsSectionContent {
  color: rgba(51, 51, 51, 0.6);
  font-size: 11pt;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  padding-left: 10px;
}

.trainingAndCredentialsSectionContent > p::before {
  content: ".";
  margin: -5px 5px 0 0;
  font-size: 20px;
  font-weight: bolder;
  line-height: 0;
}

.editProfileButton {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding: 15px 20px;
  border-radius: 10px;
  background: #518074;
  cursor: pointer;
}
.editProfileButtonIcon {
  color: #fff;
}
.editProfileButtonText {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
