.wrapper {
  width: 100%;
  height: auto;
  padding: 24px;
  border: 1px solid #33333333;
  border-radius: 10px;
  margin-bottom: 24px;
}

.memberInfoSection {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.memberImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.memberImage > img {
  max-width: 100%;
  max-height: 100%;
  border-radius: inherit;
}

.memberIntro {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.memberName {
  margin: 16px 0 8px;
  color: #37484B;
  font-size: 22pt;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.memberTitle {
  color: rgba(51, 51, 51, 0.6);
  font-size: 11pt;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.memberExpertiseSection {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.memberExpertiseTitle {
  color: rgba(51, 51, 51, 0.8);
  font-size: 11pt;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 24px 0 8px;
}
.memberExpertiseWrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.memberExpertiseItem {
  color: #C19073;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5px;
  background: #D7A97633;
  padding: 5px 10px;
  text-transform: uppercase;
}
.memberLanguageItem {
  color: #e7e7e7;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5px;
  background: #037046;
  padding: 5px 10px;
  text-transform: uppercase;
}
.memberEmail {
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.memberEmailIcon {
  color: #033b0a;
}
.memberEmailLink {
  width: 100%;
  color: #033b0a;
  font-size: 11pt;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  overflow-wrap: break-word;
}

.memberLocation {
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.memberLocationIcon {
  color: #033b0a;
}
.memberLocationText {
  color: #033b0a;
  font-size: 11pt;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.memberVirtualSessions {
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.memberVirtualSessions {
  color: #033b0a;
}
.memberVirtualSessions {
  color: #033b0a;
  font-size: 11pt;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.socialLinksContainer{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 18px;
}

.socialLinkIcon{
  font-size: 26px;
  cursor: pointer;
  color: #033b0a;
}