.calenderWrapper{
    width: 100%;
    border: 1px solid #a5a4a4;
    border-radius: 8px;
}

.timeSlotWrapper{
    width: 100%;
    height: auto;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    row-gap: 12px;
    column-gap: 12px;
}

.timeSlotItem{
    width: 80px;
    font-size: 18px;
    padding: 8px 12px;
    border: 1px solid #a5a4a4;
    border-radius: 10px;
    cursor: pointer;
}

.selectedTimeSlotItem{
    width: 80px;
    font-size: 18px;
    padding: 8px 12px;
    border: 2px solid #0c5e0c;
    border-radius: 10px;
    cursor: pointer;
}

.sectionHeading{
    font-size: 18px;
    font-weight: 800;
    color: #0f0e11;
}

.paymentSectionItemDiv{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feeAndCurrency{
    font-weight: 800;
    color: #091613;
}

.totalFeeAndCurrency{
    font-weight: 800;
    color: #0b5527;
}