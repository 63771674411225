.wrapper {
  height: 150px;
  width: 100%;
  padding: 30px 42px;
  background: #F5F9F9;
}

.headerLogo {
  height: 106px;
  width: 219px;
  display: flex;
  align-items: center;
}

.headerLogo > img {
  max-height: 100%;
  width: 100%;
}

.headerNavigationWrapper {
  width: 100%;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation {
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.navigationItem {
  width: fit-content;
  min-width: fit-content;
}
.navigationItem > a {
  color: black;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
}

.navigationButton {
  padding: 18px 18px;
  color: #ffffff;
  background-color: #518074;
  border: 0px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navigation{
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    row-gap: 12px;
    font-size: 26px;
  }
}

@media (max-width: 400px) {
  .wrapper{
    padding: 30px 15px;
  }
}