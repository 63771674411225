.wrapper{
  width: 100%;
  min-height: calc(100vh - 350px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.formWrapper {
  width: 400px
}

.formHeading{
  width: 100%;
  text-align: center;
}

.formLabel {
  font-weight: 600;
  color: #37484b;
}

.formLabel > span {
  color: rgb(255, 0, 0);
  font-size: 20px;
}

.formSubmitButton {
  color: #fff;
  padding: 20px 25px;
  border: 0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  margin-top: 24px;
}

.highlightStarForTOC {
  color: #1677ff;
  font-size: 20px;
}

