.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F5F9F9;
}

.serviceHeadingSection {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 17px;
}

.serviceHeadingText {
  color: #37484B;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.serviceHeadingAddServiceButton {
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
}

.serviceHeadingAddServiceButtonIcon {
  color: #033b0a;
}
.serviceHeadingAddServiceButtonText {
  color: #033b0a;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  margin: 0;
}
.serviceList {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 17px;
}

.serviceListItem {
  width: 100%;
  display: flex;
  column-gap: 21px;
}

.serviceListItemImageSection {
  width: 250px;
  height: 250px;
  position: relative;
  border-radius: 10px;
}

.serviceListItemImage {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
}

.serviceListItemTimeIcon {
  color: rgba(0, 0, 0, 0.8);
  margin-top: 2px;
}
.serviceListItemTimeText {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.serviceListItemEditButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: absolute;
  top: 5px;
  right: 50px;
  cursor: pointer;
}

.serviceListItemDeleteButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.serviceListItemInfoSection {
  width: calc(100% - 271px);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
}

.serviceListItemInfoSectionTopLine {
  width: 100%;
  height: auto;
  display: flex;
  column-gap: 16px;
}

.serviceListItemName {
  width: fit-content;
  margin: 0;
  color: #37484B;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.serviceListItemPrice {
  width: fit-content;
  height: auto;
  color: rgba(51, 51, 51, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.serviceListItemPrice  > b{
  color: #37484B;
}

.serviceListItemTimeText{
  color: rgba(51, 51, 51, 0.6);;
}
.serviceListItemTimeText > b{
  color: #37484B;
}

.serviceListItemInfoSectionDescription {
  width: 100%;
  height: auto;
}
.serviceListItemInfoSectionWellnessKeywords {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 8px;
  row-gap: 8px;
}

.serviceListItemInfoSectionWellnessKeywordItem {
  width: fit-content;
  color: #C19073;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5px;
  background: #D7A97633;
  padding: 3px 5px;
  text-transform: uppercase;
}

.bookingButton{
  padding: 6px 8px;
  border: 0;
  border-radius: 5px;
  background: #518074;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 600px) {
  .serviceListItem{
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
  .serviceListItemInfoSection{
    width: 100%;
  }
}