.container {
  width: 100%;
  height: 100%;
  padding: 50px 250px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: #F5F9F9
}

.leftSideContainer {
  width: 30%;
}

.imageContainer {
  height: 357px;
  width: 90%;
  margin-top: 70px;
}

.imageContainer > img {
  object-fit: cover;
  max-width: 100%;
  border-radius: 182px;
}

.rightSideContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.rightSideHeading {
  font-size: 44.8px;
  font-weight: 500;
  margin-bottom: 24px;
  color: #37484B;
}

.rightSideText {
  margin-bottom: 24px;
  width: 76%;
  line-height: 24px;
}

.formLabel {
  font-weight: 600;
  color: #37484B;
}

.formLabel > span {
  color: rgb(255, 0, 0);
  font-size: 20px;
}

.formSubmitButton {
  color: #fff;
  padding: 20px 25px;
  border: 0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
}

.loginRedirectButton {
  cursor: pointer;
  color: #1677ff;
  font-weight: 600;
}

.highlightStarForTOC {
  color: #1677ff;
  font-size: 20px;
}

.phoneContainer {
  width: 100%;
}

.phoneInputButton {
  border-radius: 6px !important;
  border: solid 1px #dfe5e9 !important;
}

.phoneInput {
  width: 100% !important;
  height: 40px !important;
  border-radius: 6px !important;
  border: solid 1px #dfe5e9 !important;
}
.qualificationItem{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qualificationItem > div{
  width: 90%;
}

.qualificationItem > span{
  font-size: 22px;
  color: red;
  margin-bottom: 24px;
}

@media (max-width: 1300px) {
  .container{
    padding: 30px 100px;
  }
}

@media (max-width: 1000px) {
  .container{
    padding: 30px 100px;
  }
  .leftSideContainer{
      width: 0;
      display: none;
  }
  .rightSideContainer{
    width: 100%;
  }
}

@media (max-width: 550px) {
  .container{
    padding: 30px 30px;
  }
  .rightSideText{
    width: 100%;
  }
}
