.pageHeader{
    width: calc(100% - 60px);
    margin: 30px 0 30px 60px;
    text-align: center;
    color: rgb(48, 48, 51);
}

.WellnessFocusCardWrapper{
    width: calc(100% - 60px);
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 24px;
    column-gap: 24px;
    margin: 0 30px;
}

.WellnessFocusCard{
    width: 260px;
    height: 260px;
    /* border: 1px solid #c1c1c1; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.WellnessFocusCard_name{
    text-align: center;
    margin-bottom: 5px;
    font-weight: 500;
}

.WellnessFocusCard_imageWrapper{
    width: 200px;
    height: 200px;
    max-width: 200px;
    max-height: 200px;
    padding: 30px;
    border-radius: 50%;
    box-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E4F3F8;
}

.WellnessFocusCard_image{
    max-width: 100px;
    max-height: 100px;
}