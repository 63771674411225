/* PaymentWaitingPage.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  row-gap: 24px;
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

/* Custom styles for your payment form */
.paymentContainer {
  padding: 20px;
  margin: 36px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.paymentContainer button {
  background-color: #0570de;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin: auto;
  margin-top: 24px;
}

.paymentContainer button:hover {
  background-color: #045bbf;
}

.slidingScaleBox {
  width: 350px;
  margin: 24px auto;
  border: 1px solid #c1c1c1;
  border-radius: 12px;
  padding: 12px;
}

.paymentSectionItemDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feeAndCurrency {
  font-weight: 800;
  color: #091613;
}

.totalFeeAndCurrency {
  font-weight: 800;
  color: #0b5527;
}

.space{
  height: 24px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
