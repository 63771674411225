.pricingContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }
  
  .heading {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  .pricingOptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .plan {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 30px;
    width: 100%;
    max-width: 350px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .plan:hover {
    transform: translateY(-10px);
  }
  
  .planTitle {
    font-size: 1.8rem;
    color: #222;
    margin-bottom: 20px;
  }
  
  .price {
    font-size: 1.5rem;
    color: #518074;
    margin-bottom: 20px;
  }
  
  .features {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .feature {
    font-size: 1.1rem;
    margin: 10px 0;
  }
  
  .ctaButton {
    background-color: #518074;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .ctaButton:hover {
    background-color: #3b5e55;
  }
  
  @media (max-width: 768px) {
    .pricingOptions {
      flex-direction: column;
    }
  }
  