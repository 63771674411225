.formLabel {
  font-weight: 600;
  color: #37484B;
}

.formLabel > span {
  color: rgb(255, 0, 0);
  font-size: 20px;
}

.formSubmitButton {
  color: #fff;
  background: #518074;
  padding: 20px 25px;
  border: 0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.avatarSectionWrapper{
    margin-bottom: 24px;
}

.ImageUploadSection{
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.imagesDivider{
    width: 100%;
    height: auto;
    text-align: center;
    margin: 24px 0;
    color: #838080;
}

.stockImageHorizontalList {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 24px;
}
.preloadedImageWrapper {
  height: 100px;
  width: 100px;
  flex: 0 0 auto;
  margin: 10px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.preloadedImageSelected {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  border: 2px solid  #2c2c2b;
  border-color: #2c2c2b;
}
.preloadedImage {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}
