.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 24px;
  padding: 50px 100px;
  background: #F5F9F9;
}

.leftSideWrapper {
  width: 30%;
  height: auto;
}
.rightSideWrapper {
  width: 70%;
  height: auto;
}

.alertForInactiveUserWrapper {
  width: 70%;
  margin-bottom: 50px;
}

.ctaContainer {
  padding: 24px;
  color: rgba(51, 51, 51, 0.659, 0.6);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  font-family: "Inter" !important;
  border: 1px solid #33333333;
  border-radius: 10px;
}

.lastLine {
  width: 100%;
  text-align: center;
}

.ctaHeading {
  color: #37484B;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: "Inter";
}

.appStoreCtaSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.appStoreImage {
  max-width: 195px;
  cursor: pointer;
}

.playStoreImage {
  max-width: 244px;
  margin-right: -16px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
  }
  .leftSideWrapper {
    width: 100%;
  }
  .rightSideWrapper {
    width: 100%;
  }
}

@media (max-width: 750px) {
  .wrapper {
    padding: 24px 50px 24px 50px;
  }
  .appStoreCtaSection{
    align-items: center;
  }
}

@media (max-width: 450px) {
  .wrapper {
    padding: 24px 16px 24px 16px;
  }
}

@media (max-width: 595px) {
  .appStoreImage {
    margin-left: 18px;
  }
}