.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventHeadingSection {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 17px;
}

.eventHeadingText {
  color: #37484b;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.eventHeadingAddEventButton {
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
}

.eventHeadingAddEventButtonIcon {
  color: #033b0a;
}
.eventHeadingAddEventButtonText {
  color: #033b0a;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  margin: 0;
}
.eventList {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}

.eventListItem {
  width: 100%;
  display: flex;
  column-gap: 21px;
}

.eventListItemImageSection {
  width: 250px;
  height: 250px;
  position: relative;
  border-radius: 10px;
}

.eventListItemImage {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
}

.eventListItemPrice {
  height: 33px;
  color: rgba(51, 51, 51, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.eventListItemEditButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: absolute;
  top: 5px;
  right: 50px;
  cursor: pointer;
}

.eventListItemDeleteButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.eventListItemInfoSection {
  width: calc(100% - 271px);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
}

.eventListItemInfoSectionTopLine {
  width: 100%;
  height: auto;
  display: flex;
  column-gap: 16px;
}

.eventListItemName {
  width: fit-content;
  margin: 0;
  color: #37484b;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.eventListItemLocation {
  width: 100%;
  overflow-wrap: break-word;
  margin: 0;
}

.eventListItemLink {
  width: 100%;
  overflow-wrap: break-word;
}

.eventListItemTimeText {
  color: rgba(51, 51, 51, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.eventListItemTimeText > b {
  color: #37484b;
}

.eventListItemInfoSectionDescription {
  width: 100%;
  height: fit-content;
}
.eventListItemInfoSectionWellnessKeywords {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 8px;
  row-gap: 8px;
}

.eventListItemInfoSectionWellnessKeywordItem {
  width: fit-content;
  color: #c19073;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5px;
  background: #d7a97633;
  padding: 3px 5px;
  text-transform: uppercase;
}

.registerButton {
  padding: 6px 8px;
  border: 0;
  border-radius: 5px;
  background: #518074;
  color: #fff;
  cursor: pointer;
}
@media (max-width: 600px) {
  .eventListItem {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
  .eventListItemInfoSection {
    width: 100%;
  }
  .eventHeadingSection {
    flex-direction: column-reverse;
    row-gap: 16px;
  }
  .eventHeadingAddEventButton {
    align-self: flex-end;
  }
}
