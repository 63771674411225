.wrapper {
  background: #f5f9f9;
  min-height: 100vh;
}

.appointmentCard {
  width: calc(100% - 48px);
  margin: 24px;
  padding: 24px;
  height: auto;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
}

.acceptButton {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  background: #0a9628;
  color: #fff;
  border: 0;
  margin-right: 24px;
  cursor: pointer;
}

.completeButton {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  background: #0a9628;
  color: #fff;
  border: 0;
  margin-right: 24px;
  cursor: pointer;
}
.rescheduleButton {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  background: #048292;
  color: #fff;
  border: 0;
  margin-right: 24px;
  cursor: pointer;
}
.cancelButton {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  background: #c71010;
  color: #fff;
  border: 0;
  margin-right: 24px;
  cursor: pointer;
}
.paymentButton {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  background: #0a9628;
  color: #fff;
  border: 0;
  margin-right: 24px;
  cursor: pointer;
}

.paymentInfoText {
  margin-top: 12px;
  font-weight: 700;
}

.paymentSectionItemDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feeAndCurrency {
  font-weight: 800;
  color: #091613;
}

.totalFeeAndCurrency {
  font-weight: 800;
  color: #0b5527;
}
