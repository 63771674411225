.formLabel {
  font-weight: 600;
  color: #37484b;
}

.formLabel > span {
  color: rgb(255, 0, 0);
  font-size: 20px;
}

.formSubmitButton {
  width: 100%;
  color: #fff;
  background: #518074;
  padding: 15px 25px;
  border: 0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.sliderPointMessage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: -24px;
  font-size: 12px;
}
