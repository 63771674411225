.wrapper{
    background: #F5F9F9;
    min-height: 100vh;
  }
  
  .inputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    padding: 24px;
  }
  
  .locationInput {
    width: 300px;
  }
  /* 
  .searchButtonWrapper {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  
  .searchButton {
    width: fit-content;
    color: #fff;
    background: #61A073;
    padding: 10px 15px;
    border: 0;
    border-radius: 10px;
    /* font-weight: bold; */
    font-size: 14px;
    cursor: pointer;
  }
  
  .suggestedWellnessKeywordsSection{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10px;
  }
  
  .suggestedWellnessKeyword_selected{
    width: fit-content;
    padding: 8px;
    background: #61A073;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .suggestedWellnessKeyword_all{
    width: fit-content;
    padding: 8px;
    background: #37484B;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .suggestedWellnessKeyword{
    width: fit-content;
    padding: 8px;
    background: #E5ECE7;
    color: #37484B;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .memberSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
  }
  
  .memberListWrapper {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    row-gap: 24px;
    column-gap: 24px;
  }
  
  .memberListCard {
    width: 250px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #80c58d34;
    border-radius: 8px;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.1);
  }
  
  /* .memberListCard:hover {
    transform: translateY(-5px);
    box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.397);
  } */
  
  .memberListImageWrapper {
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
  }
  
  .memberListImage {
    max-height: 150px;
    max-width: 150px;
    height: 150px;
    width: 150px;
    
    margin: auto;
    border-radius: 50%;
    border: 3px solid #fff;
  }
  
  .memberListInfoWrapper{
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .memberListInfo{
  
  }
  
  .memberListName {
    width: 100%;
    text-align: center;
    font-size: 14pt;
    margin: 0;
    margin: 10px 0 6px;
    padding: 0 10px;
    font-weight: 400;
    color: #37484B;
  }
  
  .memberListTitle {
    width: 100%;
    text-align: center;
    font-size: 10pt;
    padding: 0 10px;
    color: #37484bb9;
    font-weight: 600;
  }
  
  .memberListButton{
    width: fit-content;
    height: fit-content;
    padding: 8px 12px;
    background: #61A073;
    border-radius: 10px;
    color: #fff;
    margin:  0 auto 5px;
    cursor: pointer;
  }
  
  .modalImageWrapper {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .downloadAppSection {
    width: 100%;
    background: #61A073;
    color: #fff;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  .downloadAppSectionText {
    width: fit-content;
    height: auto;
    margin: 0;
  }
  
  .appStoreImagesWrapper {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .appStoreImage {
    max-width: 195px;
    cursor: pointer;
  }
  
  .playStoreImage {
    max-width: 244px;
    margin-right: -16px;
    cursor: pointer;
  }
  
  .memberExpertiseWrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 12px 0 24px;
  }
  
  .memberExpertiseItem {
    color: #61A073;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 5px;
    background: #80c58d4d;
    padding: 5px 10px;
    text-transform: uppercase;
  }
  
  .trainingAndCredentialsSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  
  .trainingAndCredentialsSectionContent {
    color: rgba(51, 51, 51, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
  }
  
  .trainingAndCredentialsSectionContent > p::before{
   content: ".";
   margin: -5px 5px 0 0;
   font-size: 20px;
   font-weight: bolder;
   line-height: 0;
  }
  
  .allWellnessKeywords{
    width: 100%;
    padding: 0 24px;
    column-count: 4; 
    column-gap: 20px; 
    max-height: 600px; 
    overflow: auto;
    margin-top: 24px;
  }
  
  .wellnessKeywordListItem{
    cursor: pointer;
    font-size: 14pt;
  }
  
  .searchIllustrationWrapper{
  width: 300px;
  height: 300px;
  
  }
  
  .searchIllustrationImage{
    max-height: 100%;
    max-width: 100%;
  }

  
.navigationButton {
  width: 300px;
  padding: 18px 18px;
  color: #ffffff;
  background-color: #518074;
  border: 0px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  margin: auto;
}
  
  @media (max-width: 770px) {
    .allWellnessKeywords{
      column-count: 3; 
    }
  }
  
  @media (max-width: 520px) {
    .inputs{
      flex-direction: column;
      row-gap: 24px;
    }
    .allWellnessKeywords{
      column-count: 2; 
    }
  }
  
  @media (max-width: 490px) {
    .appStoreImagesWrapper{
      height:auto;
      flex-direction: column;
      row-gap: 24px;
      align-items: center;
    }
  }