.formLabel {
  font-weight: 600;
  color: #37484B;
}

.formLabel > span {
  color: rgb(255, 0, 0);
  font-size: 20px;
}

.formSubmitButton {
  color: #fff;
  background: #518074;
  padding: 20px 25px;
  border: 0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.phoneContainer {
  width: 100%;
}

.phoneInputButton {
  border-radius: 6px !important;
  border: solid 1px #dfe5e9 !important;
}

.phoneInput {
  width: 100% !important;
  height: 40px !important;
  border-radius: 6px !important;
  border: solid 1px #dfe5e9 !important;
}

.qualificationItem{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qualificationItem > div{
  width: 90%;
}

.qualificationItem > span{
  font-size: 22px;
  color: red;
  margin-bottom: 24px;
}