.wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 24px;
  padding: 0 100px 0 100px;
  background: #F5F9F9;
}

.leftSideWrapper {
  width: 30%;
  height: auto;
}
.rightSideWrapper {
  width: 70%;
  height: auto;
}

.alertForInactiveUserWrapper {
  width: 70%;
  margin-bottom: 50px;
}

@media (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
  }
  .leftSideWrapper {
    width: 100%;
  }
  .rightSideWrapper {
    width: 100%;
  }
}

@media (max-width: 750px) {
  .wrapper {
    padding: 0 50px 0 50px;
  }
}

@media (max-width: 450px) {
  .wrapper {
    padding: 0 16px 0 16px;
  }
}