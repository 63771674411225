.main {
  width: 100%;
  height: 800px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f9f9;
  color: #37484b;
  font-family: "Inter", serif;
  font-size: 16px;
  overflow-y: auto;
}

.focusArea_imageWrapper {
  width: 300px;
  height: 300px;
  max-width: 300px;
  max-height: 300px;
  padding: 30px;
  border-radius: 10%;
  box-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dff2df;
  margin: 24px 0;
}

.focusArea_image {
  max-width: 200px;
  max-height: 200px;
}

.focusArea_name {
  font-weight: 600;
  text-align: center;
  font-size: 22px;
}

.focusArea_description {
  text-align: center;
  padding: 0 30px;
}

.member_heading {
  text-align: center;
  margin: 24px 0 0;
  font-size: 36px;
  color: #518074;
}

.memberCardsWrapper {
  width: calc(100% - 60px);
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 42px;
  column-gap: 24px;
  margin: 24px 30px 30px;
}

.memberCardWrapper {
  width: 260px;
  height: 324px;
  /* border: 1px solid #c1c1c1; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.member_imageWrapper {
  width: 200px;
  height: 200px;
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  border-radius: 50%;
  box-shadow: 1.6px 3.2px 3.2px hsl(0deg 0% 0% / 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dff2df;
  margin-bottom: 12px;
}

.member_image {
  max-width: 180px;
  max-height: 180px;
  border-radius: 50%;
}

.member_name {
  text-align: center;
  margin-bottom: 8px;
}

.member_title {
  width: 100%;
  height: 55px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 8px;
  max-height: 55px;
  line-height: 18px;
}

.member_location {
  margin: 0;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.empty_container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px;
}

.memberListWrapper {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 24px;
  column-gap: 24px;
}

.memberListCard {
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #80c58d34;
  border-radius: 8px;
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.1);
}

/* .memberListCard:hover {
  transform: translateY(-5px);
  box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.397);
} */

.memberListImageWrapper {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
}

.memberListImage {
  max-height: 150px;
  max-width: 150px;
  height: 150px;
  width: 150px;

  margin: auto;
  border-radius: 50%;
  border: 3px solid #fff;
}

.memberListInfoWrapper {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.memberListInfo {
}

.memberListName {
  width: 100%;
  text-align: center;
  font-size: 14pt;
  margin: 0;
  margin: 10px 0 6px;
  padding: 0 10px;
  font-weight: 400;
  color: #37484b;
}

.memberListTitle {
  width: 100%;
  text-align: center;
  font-size: 10pt;
  padding: 0 10px;
  color: #37484bb9;
  font-weight: 600;
}

.memberListButton {
  width: fit-content;
  height: fit-content;
  padding: 8px 12px;
  background: #61a073;
  border-radius: 10px;
  color: #fff;
  margin: 0 auto 5px;
  cursor: pointer;
}
