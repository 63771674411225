.container{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.upgradeButton {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding: 15px 20px;
  border-radius: 10px;
  background: #518074;
  cursor: pointer;
  color: #fff;
}
