.aTag{
  all: unset;
  cursor: pointer;
}

.footerWrapper{
  width: 100%;
  height: auto;
}

.bottomFooterWrapper {
  width: 100%;
  min-height: 100px;
  height: auto;
  padding: 30px 0;
  background: #518074;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.copyrightText {
  width: 100%;
  text-align: center;
  color: #80c58d;
  font-size: 16px;
}

.copyrightText > p > a {
  color: #80c58d !important;
  text-decoration: underline;
}
.footerSocialMediaWrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 18px;
  font-size: 24px;
  color: #fff;
  margin-bottom: 24px;
}