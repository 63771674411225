.successPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .greenTitle {
    color: #4CAF50; /* Green */
    margin-bottom: 20px;
  }

  .redTitle {
    color: #D32F2F; /* Green */
    margin-bottom: 20px;
  }
  
  .message {
    margin-bottom: 40px;
    color: #666;
    max-width: 600px;
  }
  
  .greenContinueButton {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  
  .redContinueButton {
    background-color: #1976D2; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  