.wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F5F9F9;
  }
  
  .contentHeadingSection {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 17px;
  }
  
  .contentHeadingText {
    color: #37484B;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .contentHeadingAddContentButton {
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  
  .contentHeadingAddContentButtonIcon {
    color: #033b0a;
  }
  .contentHeadingAddContentButtonText {
    color: #033b0a;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    margin: 0;
  }
  .contentList {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 17px;
  }
  
  .contentListItem {
    width: 100%;
    display: flex;
    column-gap: 21px;
    position: relative;
  }
  
  .contentListItemImageSection {
    width: 250px;
    height: 250px;
    position: relative;
    border-radius: 10px;
  }
  
  .contentListItemImage {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
  }
  
  .contentListItemTimeIcon {
    color: rgba(0, 0, 0, 0.8);
    margin-top: 2px;
  }
  .contentListItemTimeText {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  
  .contentListItemEditButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    position: absolute;
    top: 5px;
    right: 50px;
    cursor: pointer;
  }
  
  .contentListItemDeleteButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  
  .contentListItemInfoSection {
    width: calc(100% - 271px);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
  }
  
  .contentListItemInfoSectionTopLine {
    width: 100%;
    height: auto;
    display: flex;
    column-gap: 16px;
  }
  
  .contentListItemName {
    width: fit-content;
    margin: 0;
    color: #37484B;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .contentListItemPrice {
    width: fit-content;
    height: auto;
    color: rgba(51, 51, 51, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .contentListItemPrice  > b{
    color: #37484B;
  }
  
  .contentListItemTimeText{
    color: rgba(51, 51, 51, 0.6);;
  }
  .contentListItemTimeText > b{
    color: #37484B;
  }
  
  .contentListItemInfoSectionDescription {
    width: 100%;
    height: auto;
  }
  .contentListItemInfoSectionWellnessKeywords {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 8px;
    row-gap: 8px;
  }
  
  .contentListItemInfoSectionWellnessKeywordItem {
    width: fit-content;
    color: #C19073;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 5px;
    background: #D7A97633;
    padding: 3px 5px;
    text-transform: uppercase;
  }
  
  @media (max-width: 600px) {
    .contentListItem{
      flex-direction: column;
      align-items: center;
      row-gap: 30px;
    }
    .contentListItemInfoSection{
      width: 100%;
    }
  }