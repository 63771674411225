.wrapper {
  width: 100%;
  height: auto;
  padding: 24px;
  border: 1px solid #33333333;
  border-radius: 10px;
  margin-bottom: 24px;
}

.headingSectionWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.heading {
  color: rgba(51, 51, 51, 0.8);
  font-size: 14pt;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.writeAReview {
  color: #51756b;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.reviewProgressWrapper {
  width: 100%;
}

.writtenReviewsWrapper {
  width: auto;
}

.reviewsWrapper {
  width: 100%;
  max-height: 260px;
  display: flex;
  overflow-x: auto; /* Allows horizontal scrolling */
  scroll-snap-type: x mandatory;
  padding: 5px;
  gap: 20px;
}

.reviewsWrapper::-webkit-scrollbar {
  height: 0.5em;
}

.reviewsWrapper::-webkit-scrollbar-thumb {
  background-color: #51756b;
  border-radius: 12px;
}

.reviewWrapper {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  padding: 8px;
  scroll-snap-align: center;
  flex-shrink: 0;
}

.reviewText {
  max-height: 175px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #51756b transparent;
}

.reviewText::-webkit-scrollbar {
  width: 0.5em; /* Custom scrollbar width for WebKit browsers */
}

.reviewText::-webkit-scrollbar-thumb {
  background-color: #51756b; /* Thumb color */
  border-radius: 12px; /* Rounded corners for the scrollbar thumb */
}

.reviewText::-webkit-scrollbar-track {
  background-color: transparent; /* Hide the track when not needed */
}

.reviewUserDetailsWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reviewUserDetailsImage {
  max-width: 24px;
  max-height: 24px;
  border-radius: 50%;
}

.reviewUserDetailsName {
}

.reviewListItemEditButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dadada;
  top: 5px;
  right: 50px;
  cursor: pointer;
}
